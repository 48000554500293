import axios, { AxiosResponse } from "axios"
import settings from "@/core/settings"
import { recordSearch } from "@/search/analytics/impression"
import { reportSearchClick } from "@/search/analytics/click"
import getSearchSessionParams from "./sessionParams"
import logger from "@/core/logger"
import { buildSearchQuery } from "./graphql"
import { InputSearchQuery, SearchOptions, SearchQuery, SearchResult } from "./types"
import { getVariations, setVisitId, storeVariations } from "./variations"
import { Maybe } from "@/types"
import { errorMessage, isAxiosError } from "@/utils/error"
import { initSessionParams } from "@/search/sessionParams"
import bus from "@/core/api/bus"
import measurePerformance from "@/core/measurePerformance"
import mode from "@/core/mode"

class SearchError extends Error {
  readonly status: number

  constructor(message: string, status?: number) {
    super(message)
    this.status = status ?? 0
  }
}

type SearchResponse = {
  data?: {
    search: SearchResult
  }
  errors?: {
    message: string
    key?: string
  }[]
}

export function initSearch() {
  if (settings.searchEnabled) {
    bus.on("prerequest", reportSearchClick)
    bus.on("taggingsent", response => setVisitId(response.visit))
    initSessionParams()
  }
}

function isSearchTemplates() {
  return !!(
    settings.searchEnabled &&
    settings.searchTemplatesEnabled &&
    (mode.isPreview() || settings.searchDeploymentId)
  )
}

/**
 * Search function
 */
export default async function search(query: SearchQuery, options?: SearchOptions): Promise<SearchResult> {
  const validateError = validateQuery(query)
  if (validateError) {
    return Promise.reject(validateError)
  }

  const sessionParams = await getSearchSessionParams()

  const queryBody = buildSearchQuery(query)
  const queryVariables = {
    accountId: settings.account,
    query: query.query,
    segments: query.segments,
    products: query.products
      ? {
          ...query.products,
          fields: undefined
        }
      : undefined,
    keywords: query.keywords
      ? {
          ...query.keywords,
          facets: undefined,
          fields: undefined
        }
      : undefined,
    sessionParams,
    rules: query.rules,
    abTests: getVariations() ?? [],
    customRules: query.customRules,
    explain: query.explain,
    redirect: query.redirect,
    time: query.time
    // Ensure that each key is provided
  } satisfies Record<keyof InputSearchQuery, unknown>

  try {
    return await performSearch(queryBody, queryVariables, query, options)
  } catch (err) {
    if (err instanceof SearchError) {
      bus.emit("searchfailure", {
        query,
        graphqlQuery: queryBody,
        graphqlVariables: queryVariables,
        error: err.message
      })
    }
    throw err
  }
}

function getIntegration() {
  return isSearchTemplates() ? "Search Templates" : "Client Script"
}

async function performSearch(queryBody: string, queryVariables: object, query: SearchQuery, options?: SearchOptions) {
  const { redirect = false, track, isKeyword } = options || {}

  const requestBody = JSON.stringify({
    query: queryBody,
    variables: queryVariables
  })

  const response = await handleSearchResponse(
    measurePerformance("nosto.search", () =>
      axios.post(getSearchUrl(), requestBody, {
        headers: {
          "Content-Type": "text/plain",
          "X-Nosto-Integration": getIntegration()
        }
      })
    )
  )

  bus.emit("searchsuccess", {
    query,
    graphqlQuery: queryBody,
    graphqlVariables: queryVariables,
    response
  })

  if (track) {
    recordSearch(track, query, response, {
      isKeyword
    })
  }

  if (response.redirect && redirect) {
    window.location.href = response.redirect
    // TODO this short circuiting should be encoded better in the type
    return new Promise<SearchResult>(() => {})
  }

  if (response.abTests) {
    storeVariations(response.abTests)
  }

  return response
}

async function handleSearchResponse(response: Promise<AxiosResponse<SearchResponse>>) {
  try {
    const { data, status } = await response
    const errors = Array.isArray(data.errors) ? data.errors.map(e => e.message) : []
    if (data.data?.search) {
      if (errors.length) {
        logger.warn(`Search has warnings: ${errors.join(", ")}`)
      }
      return data.data.search
    }
    if (errors.length) {
      throw new SearchError(`Search failed with errors: ${errors.join(", ")}, status: ${status}`, status)
    }
    throw new SearchError(`Search failed with unknown error, status: ${status}`, status)
  } catch (err) {
    if (isAxiosError<SearchResponse>(err)) {
      const errors = err.response?.data?.errors
      let msg = Array.isArray(errors) ? errors.map(e => e.message).join(", ") : err.message
      if (err.response?.status === 400) {
        // included query in error message for easier debugging
        msg = `${msg} for request ${err.config?.data}`
      }
      throw new SearchError(`Search failed with axios error: ${errorMessage(err, msg)}`, err.response?.status)
    }
    if (err instanceof Error) {
      throw err
    }
    throw new SearchError("Search failed with generic error: " + err)
  }
}

function validateQuery(query: SearchQuery): Maybe<Error> {
  if (!query) {
    return new Error("query is required")
  }
  if (query.products && query.products.fields && Array.isArray(query.products.fields)) {
    const err = query.products.fields.find(value => !value.split(".").every(v => v.match(/^[a-zA-Z]+[0-9]?$/)))
    if (err) {
      return new Error(`Invalid search field: ${err}`)
    }
  }
  return undefined
}

function getSearchUrl(): string {
  if (settings.searchApiUrl && settings.searchApiUrl.endsWith("/api/")) {
    return `${settings.searchApiUrl.slice(0, -5)}/v1/graphql`
  }
  return settings.searchApiUrl!
}
