import context from "@/core/context"
import page from "@/core/page"
import findProducts from "@/metadata/product/product"
import findCustomer from "@/metadata/customer"
import findCart from "@/metadata/cart"
import findOrder from "@/metadata/order"
import emptyToUndefined from "@/metadata/utils/empty"
import texts from "@/metadata/utils/texts"
import keyValueTexts from "@/metadata/utils/keyvalue"
import findPluginVersions from "@/metadata/plugin"
import { Maybe, PageType, pageTypeAliases } from "@/types"

const aliasMapping = Object.entries(pageTypeAliases).reduce<Record<string, string>>((acc, [key, values]) => {
  values.forEach(value => (acc[value] = key))
  return acc
}, {})

function findCurrentVariation(): Maybe<string> {
  const value = texts(page.selectAll(".nosto_variation"))[0]
  return value && value.trim() ? value : findCurrentPriceVariation()
}

function findCurrentPriceVariation(): Maybe<string> {
  return texts(page.selectAll(".nosto_price_variation"))[0]
}

function findSearchTerms(): Maybe<string[]> {
  const terms = texts(page.selectAll(".nosto_search_term"))
  return emptyToUndefined(terms)
}

function findCurrentCategories(): Maybe<string[]> {
  let cats = texts(page.selectAll(".nosto_category > .category_string"))
  if (!cats.length) {
    cats = texts(page.selectAll(".nosto_category"))
  }
  return emptyToUndefined(cats)
}

function findCurrentCategoryIds(): Maybe<string[]> {
  const ids = texts(page.selectAll(".nosto_category > .id"))
  return emptyToUndefined(ids)
}

function findCurrentParentCategoryIds(): Maybe<string[]> {
  const ids = texts(page.selectAll(".nosto_category > .parent_id"))
  return emptyToUndefined(ids)
}

function findCurrentTags(): Maybe<string[]> {
  const tags = texts(page.selectAll(".nosto_tag"))
  return emptyToUndefined(tags)
}

function findCurrentCustomFields(): Maybe<Record<string, string[]>> {
  const fields = keyValueTexts(page.selectAll(".nosto_custom_field"))
  const hasValue = fields && Object.keys(fields).length > 0
  return hasValue ? fields : undefined
}

function findRestoreLink(): Maybe<string> {
  return texts(page.selectAll(".restore_link"))[0]
}

function findAffinitySignals(): Maybe<Record<string, string[]>> {
  const fields = keyValueTexts(page.selectAll(".nosto_affinity_signals"))
  const hasValue = fields && Object.keys(fields).length > 0
  return hasValue ? fields : undefined
}

function findPageType(): Maybe<PageType> {
  const values = texts(page.selectAll(".nosto_page_type"))
  if (values.length) {
    const normalized = values[0].toLowerCase()
    return (aliasMapping[normalized] || normalized) as PageType
  }
}

function findSortOrder(): Maybe<string> {
  const values = texts(page.selectAll(".nosto_sort_order"))
  return values.length ? values[0].toLowerCase() : undefined
}

function findElements(ignoredPlacements: string[]): Maybe<string[]> {
  const ids: string[] = []
  const nodes = page.selectAll(".nosto_element")
  for (let i = 0; i < nodes.length; i += 1) {
    if (!ignoredPlacements || ignoredPlacements.indexOf(nodes[i].id) === -1) {
      ids.push(nodes[i].id)
    }
  }
  return ids
}

function findForcedSegments(): Maybe<string[]> {
  const nostosegments = context.siteUrl.searchParams.get("nostosegments")
  // url parameter takes precedence over segments in cookie
  if (nostosegments) {
    let fromUrl = nostosegments.split("~")
    if (fromUrl && fromUrl.length === 1) {
      fromUrl = nostosegments.split("%7E")
    }
    return fromUrl
  }
  return undefined
}

export {
  findProducts,
  findCart,
  findCustomer,
  findOrder,
  findSearchTerms,
  findCurrentCategories,
  findCurrentCategoryIds,
  findCurrentParentCategoryIds,
  findCurrentTags,
  findCurrentCustomFields,
  findCurrentVariation,
  findElements,
  findRestoreLink,
  findAffinitySignals,
  findPageType,
  findSortOrder,
  findForcedSegments,
  findPluginVersions
}
