import windows from "./windows"

function waitForSingle(field: string, { interval = 100, timeout = 5000 }: Options = {}): Promise<unknown> {
  return new Promise((resolve, reject) => {
    let waitTime = 0
    const handle = setInterval(() => {
      waitTime += interval
      // @ts-expect-error untyped window usage
      const res = windows.site[field]
      if (res) {
        clearInterval(handle)
        resolve(res)
      } else if (waitTime >= timeout) {
        clearInterval(handle)
        reject(new Error(`Timeout waiting for window.${field}`))
      }
    }, interval)
  })
}

type Options = {
  interval?: number
  timeout?: number
}

/**
 * Wait for single or multiple fields to be available on the site window object.
 */
export function waitFor(fields: string | string[], options: Options): Promise<unknown> {
  if (typeof fields === "string") {
    return waitForSingle(fields, options)
  } else {
    return Promise.all(fields.map(f => waitForSingle(f, options)))
  }
}
