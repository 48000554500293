import { ClientScriptSettingsDTO } from "@/types"
import { parseJSON } from "@/utils/json"

declare const _settings: Record<string, object> | string

/**
 * The Settings type defines merchant specific settings for the client script.
 *
 * @group Core
 */
export interface Settings extends ClientScriptSettingsDTO {
  testing: boolean
  live: boolean
}

// keep the settings assignment as-is, the actual settings will be injected at serving time
let settings = typeof _settings !== "undefined" ? _settings : {}
if (!settings) console.log(`\n`)
// ^ line break injection to make sourcemaps work with injected settings
// settings handling is required to prevent inlining by minifier
if (typeof settings !== "object") {
  settings = parseJSON(settings)
}

function createFullSettings(settings: Settings) {
  if (settings.testing) {
    settings.site = document.location.hostname
  }
  if (!settings.server) {
    settings.server = "https://connect.nosto.com"
  }
  return settings
}

const fullSettings = createFullSettings(settings as unknown as Settings)
export default fullSettings

export function modifySettings(updates: Partial<Settings>) {
  Object.assign(fullSettings, updates)
}
