/* global _siteWindow _nostoWindow */
import logger from "@/core/logger"
import EvaluationError from "./evaluationError"

/**
 * Nosto wrapper around `setTimeout` / `setInterval` only accepts function
 * fail-fast in order to get immediate error attribution
 */
function validateTimerHandler(handler: TimerHandler) {
  if (typeof handler !== "function") {
    throw new Error("Invalid timer handler")
  }
}

function addErrorHandling(nostoWindow: Window) {
  // Register an on-error handler for the iframe window. No all the parameters
  // are passed in all browsers. They've been added here to be explicit.
  // noinspection JSUnusedLocalSymbols

  nostoWindow.onerror = (message, source, lineno, colno, err) => {
    logger.warn(message, source, lineno, colno, err)
  }
  /*
   * Wrapping setTimeout() so we can accurately report that the
   * error occurred from a recommendation template.
   */
  const originalSetTimeout = nostoWindow.setTimeout
  nostoWindow.setTimeout = (handler, timeout) => {
    validateTimerHandler(handler)
    // eslint-disable-next-line prefer-rest-params
    const args = Array.from(arguments).slice(2)
    return originalSetTimeout(() => {
      try {
        // @ts-expect-error any type
        handler.apply(this, args)
      } catch (e) {
        logger.warn(new EvaluationError(e as Error))
      }
    }, timeout)
  }

  /*
   * Wrapping setInterval() so we can accurately report that the
   * error occurred from a recommendation template.
   */
  const originalSetInterval = nostoWindow.setInterval
  nostoWindow.setInterval = (handler, delay) => {
    validateTimerHandler(handler)
    // eslint-disable-next-line prefer-rest-params
    const args = Array.from(arguments).slice(2)
    return originalSetInterval(() => {
      try {
        // @ts-expect-error any type
        handler.apply(this, args)
      } catch (e) {
        logger.warn(new EvaluationError(e as Error))
      }
    }, delay)
  }
}

// global declarations
declare const _sandboxed: unknown
declare const _nostoWindow: Window
declare const _siteWindow: Window

function createWindows() {
  const evalWindow = typeof _nostoWindow !== "undefined" ? _nostoWindow : window

  if (typeof _sandboxed !== "undefined" && typeof _siteWindow !== "undefined" && typeof _nostoWindow !== "undefined") {
    addErrorHandling(_nostoWindow)
    return {
      site: _siteWindow,
      nosto: _nostoWindow
    }
  }
  // special mode to disable nosto sandboxing in order
  // to prevent breakage of certain accessibility tools
  // like https://accessibilityinsights.io/docs/web/overview/
  // https://nostosolutions.atlassian.net/browse/NS-7253
  if (new URLSearchParams(document.location.search).has("nosto-no-sandbox")) {
    return {
      site: evalWindow,
      nosto: evalWindow
    }
  }
  // if the store is in an iframe already and nosto is included directly
  // (without the embed) then referring window.parent can throw security
  // exception for cross-origin reference
  try {
    // If evalWindow has a parent that isn't a reference to itself, and has the
    // nostojs stub, we assume that evalWindow is the nosto iframe created by the
    // embed script and that the parent window is the store window.
    if (
      evalWindow.parent &&
      evalWindow.parent !== evalWindow &&
      evalWindow.name !== "TestEm Sandbox" &&
      // @ts-expect-error unknown field
      (evalWindow.parent.embedjs || evalWindow.parent.nostojs)
    ) {
      return {
        site: evalWindow.parent,
        nosto: evalWindow
      }
    }
  } catch (e) {
    // ignore the possible security exception, then we'll continue to create the
    // iframe for us
  }
  const iframe = document.createElement("iframe")
  iframe.id = "nosto-sandbox"

  iframe.src = "javascript:false"
  iframe.title = "Nosto Sandbox"
  iframe.style.cssText = "width: 0; height: 0; border: 0; display: none;"
  if (document.body) {
    document.body.appendChild(iframe)
  } else {
    document.head.appendChild(iframe)
  }

  addErrorHandling(iframe.contentWindow!)
  return {
    site: evalWindow,
    nosto: iframe.contentWindow!
  }
}

const windows = createWindows()

// exported for tests
export function resetWindows() {
  Object.assign(windows, createWindows())
}

export default windows
