import { AxiosError } from "axios"

export class ValidationError extends Error {
  constructor(message: string) {
    super(message)
  }
}

export function isAxiosError<T>(err: unknown): err is AxiosError<T> {
  return !!(err && typeof err === "object" && "isAxiosError" in err && err.isAxiosError === true)
}

export function errorMessage(error: Error, msg?: string): string {
  if (!isAxiosError(error)) {
    return msg ?? error.message
  }
  const parts = [msg ?? error.message]
  if (error.response?.status) {
    parts.push(`status: ${error.response.status}`)
  }
  if (error.code) {
    parts.push(`code: ${error.code}`)
  }
  return parts.join(", ")
}

// check to ensure that the thrown errror is not already logged with logger
// Some of the nosto-api errors are logged immediately due to unhandled promise rejections not propagating
// e.g. nostojs(api => { api.defaultSession().viewProduct().load() })
export function isAlreadyLogged(err: unknown) {
  return err instanceof ValidationError
}
