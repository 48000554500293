import { AnalyticEventProperties, Maybe, SearchEventMetadata } from "@/types"
import { SearchQuery, SearchResult } from "../types"
import { getMetadataCache, organicQueriesCache } from "./cache"
import { getEventProperties, getResultId, checkSearchMetadata } from "./helpers"
import { SearchAnalyticsOptions, SearchTrackOptions } from "./types"
import { reportAnalytics } from "./api"
import bus from "@/core/api/bus"
import { assertValidType, validateCategoryImpression, validateSearchImpression } from "./validation"
import logger from "@/core/logger"

/**
 * Record search event, should be send on any search
 */
export function recordSearch(
  type: SearchTrackOptions,
  query: SearchQuery,
  response: SearchResult,
  options?: SearchAnalyticsOptions
) {
  assertValidType(type)
  const { isKeyword = false } = options || {}

  const properties = getEventProperties(response.abTests ?? [])

  if (response && response.products && query) {
    const productIds = (response.products.hits || []).map(hit => hit.productId).filter(Boolean)
    const from = response.products.from ? response.products.from : 0
    const page = response.products.size ? from / response.products.size + 1 : 0

    if (type === "category") {
      const metadataCache = getMetadataCache(type)
      metadataCache.reset()
      const categoryPath = response.products.categoryPath || query.products?.categoryPath
      const categoryId = response.products.categoryId || query.products?.categoryId
      const metadata = categoryPath || categoryId ? { category: categoryPath!, categoryId } : undefined

      if (metadata && validateCategoryImpression(metadata)) {
        metadataCache.set(metadata)
        const reportedData = { productIds, metadata, page, properties }
        void reportAnalytics("category", "impression", reportedData)
        bus.emit("categoryimpression", reportedData)
      } else {
        logger.warn("Valid category metadata not given")
      }

      return
    }

    const metadataCache = getMetadataCache(type)
    metadataCache.reset()
    const resultId = getResultId(type, query.query || "")
    const previousQuery = type === "serp" ? organicQueriesCache.get().slice(-2, -1)[0] : ""
    const isRefined = type === "serp" && !!previousQuery && previousQuery !== query
    const isOrganic =
      type === "autocomplete" || (type === "serp" && organicQueriesCache.get().includes(query.query || ""))

    const metadata: SearchEventMetadata = {
      query: query.query || "",
      resultId,
      isAutoCorrect: !!response.products.fuzzy,
      isAutoComplete: type === "autocomplete",
      isKeyword,
      isSorted: !!(query.products && query.products.sort),
      isOrganic,
      isRefined,
      ...(isRefined && { refinedQuery: previousQuery || "" }),
      hasResults: !!response?.products?.total || !!response.redirect
    }
    if (validateSearchImpression(metadata)) {
      metadataCache.set(metadata)
      void reportSearchImpression(productIds, metadata, page, properties)
    }
  } else {
    logger.warn("Invalid search event data")
  }
}

export async function reportSearchImpression(
  productIds: string[] = [],
  metadata: SearchEventMetadata,
  page: number = 1,
  properties: Maybe<AnalyticEventProperties>
) {
  const sentMetadata = checkSearchMetadata("impression", metadata)
  const reportedData = { productIds, metadata: sentMetadata, page, properties }
  await reportAnalytics("search", "impression", reportedData)
  bus.emit("searchimpression", reportedData)
}

/**
 * Record search submit event (e.g. search form submit). Required to track organic searches.
 */
export function recordSearchSubmit(query: string) {
  if (query) {
    organicQueriesCache.push(query)
  }
}
