import { deepClone } from "@/utils/deepClone"
import bus from "@/core/api/bus"
import createRequestBuilder from "../request"
import { pageTagging } from "."
import logger from "../logger"

/**
 * Resends all the tagging to Nosto. Only the page tagging is sent over
 * and nothing else - no placements, no events, nothing.
 * This method was originally used by the Magento 2 plugin. In the Magento 2 plugin
 * the order tagging was loaded asynchronously so a method like this was needed.
 */
export default async function resendAllTagging() {
  const tagging = deepClone(pageTagging())
  if (tagging) {
    await createRequestBuilder().setElements(undefined).send({ skipPageViews: true })
    bus.emit("taggingresent", tagging)
    return
  }
  logger.info("No tagging found.")
}
